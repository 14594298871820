import React, { useState, useEffect } from 'react';
import Dag from './Dag';
import './App.css';

function App() {
  const [winners, setWinners] = useState([]);
  const getWinner = (d) => {
    const w = (winners || []).find(winner => winner.day === d)
    return w ? w.winner : undefined
  }
  useEffect(() => {
      try {
       fetch('/api/Winners')
       .then(result => result.json()).catch((err) => console.error('2',err))
       .then(body => setWinners(body));
      } catch(err) {
        console.error(err);
      }
  }, []);

  return (
    <div>
      <div className='calendar-grid'>
        <div className="title">
          <h1><span className="title-1">Sæbø</span><span className="title-2">Skulemusikklag</span><span className="title-3">Adventskalendar</span></h1>
        </div>
        <Dag dato={1} premie="2 bill. pepperkakebyen" vinner={getWinner(1)} />
        <Dag dato={2} premie="Gåvekort bowling" vinner={getWinner(2)} />
        <Dag dato={3} premie="3L Baronesse maling &#9829;" vinner={getWinner(3)} />
        <Dag dato={4} premie="To bill. Bygdekinoen" vinner={getWinner(4)} />
        <Dag dato={5} premie="Klippekort Radøyhallen + 50 egg" vinner={getWinner(5)} />
        <Dag dato={6} premie="2 konsertbill Manger kyrkje 17.12" vinner={getWinner(6)} />
        <Dag dato={7} premie="Gåvekort bowling &#9829;" vinner={getWinner(7)} />
        <Dag dato={8} premie="Gåvekort kake" vinner={getWinner(8)} />
        <Dag dato={9} premie="Dekkskift + Vase" vinner={getWinner(9)} />
        <Dag dato={10} premie="Hudpleieprodukt" vinner={getWinner(10)} />
        <Dag dato={11} premie="Gåvekort 400kr &#9829;" vinner={getWinner(11)} />
        <Dag dato={12} premie="Matkorg" vinner={getWinner(12)} />
        <Dag dato={13} premie="Potte + 50 egg" vinner={getWinner(13)} />
        <Dag dato={14} premie="Gåvekort pizza" vinner={getWinner(14)} />
        <Dag dato={15} premie="Adventskos" vinner={getWinner(15)} />
        <Dag dato={16} premie="Dørkrans" vinner={getWinner(16)} />
        <Dag dato={17} premie="Fiskeharpe / dorg &#9829;" vinner={getWinner(17)} />
        <Dag dato={18} premie="Såpedispenser + handduk" vinner={getWinner(18)} />
        <Dag dato={19} premie="OralB elektrisk tannbørste" vinner={getWinner(19)} />
        <Dag dato={20} premie="Julematkasse" vinner={getWinner(20)} />
        <Dag dato={21} premie="Gåvekort 500kr" vinner={getWinner(21)} />
        <Dag dato={22} premie="Hodelykt &#9829;" vinner={getWinner(22)} />
        <Dag dato={23} premie="Velværeprodukt" vinner={getWinner(23)} />
        <Dag dato={24} premie="Apple Airpods" vinner={getWinner(24)} />
      </div>
    </div>
  );
}

export default App;
